import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import {
    HttpHeaders as CapacitorHttpHeaders,
    HttpParams as CapacitorHttpParams,
    CapacitorHttp as Http,
    HttpOptions,
} from '@capacitor/core';
import { Platform } from '@ionic/angular/standalone';

import { TranslateService } from '@ngx-translate/core';
import { isDate, parseISO } from 'date-fns';
import { Observable, defer, from, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { PrincipalService } from '../auth/principal.service';
import { IUser } from '../core/model/user.model';
import { environment } from '../environments/environment';
import { IonLoaderService } from '../service/loader.service';
import { PreferenceService } from '../service/preference.service';
import { SharedService } from '../service/shared.service';
import { ToastService } from '../service/toast.service';
import { deepCopy } from '../util/utils';

@Injectable()
export class CapacitorHttpInterceptor implements HttpInterceptor {
    private platform = inject(Platform);
    private _translateService = inject(TranslateService);
    private _principal = inject(PrincipalService);
    private _loaderService = inject(IonLoaderService);
    private _sharedService = inject(SharedService);
    private preference = inject(PreferenceService);
    private _toastService = inject(ToastService);

    retryCount = 3;
    retryWaitMilliSeconds = 1000;
    lang = 'en';
    user: IUser;
    _isoDateFormat = /^\d{4}-\d{2}-\d{2}/;
    _isoDateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    constructor() {
        this.preference.user$.subscribe(session => {
            if (session.authenticated) {
                this.user = session.user;
            } else {
                this.user = undefined;
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._sharedService.changeApiProgress.next(true);
        if (request.body && !(request.body instanceof FormData)) {
            // this.convertRequest(Object.assign({}, request.body));
            let data = deepCopy(request.body);
            data = this.convertRequest(data);
            request = request.clone({
                body: data,
            });
        }
        if (this.platform.is('capacitor') && environment.development) {
            console.log('NATIVE HTTP CALL');
            return this.interceptNativeRequest(request, next);
        }
        return this.interceptWebRequest(request, next);
    }

    private interceptNativeRequest(req: HttpRequest<any>, _next: HttpHandler): Observable<HttpEvent<any>> {
        const { method, body, url, headers, params } = req;
        console.log(
            'ORIGINAL ANGULAR REQUEST',
            req,
            method,
            body,
            url.startsWith('https') ?? environment.apiUrl + url,
            headers,
            params
        );
        /**
         * Transforms the type signature of Angular http headers
         * to Capacitor's type signature for http headers.
         *
         * Sanitizes invalid header values from the output.
         */
        const sanitizeHeaders = (headers: HttpHeaders) => {
            const res: CapacitorHttpHeaders = {};
            for (const key of headers.keys()) {
                res[key] = decodeURIComponent(params.get(key) || '');
            }
            return res;
        };
        /**
         * Transforms the type signature of Angular http params
         * to Capacitor's type signature for http params.
         *
         * Sanitizes invalid param values from the output.
         */
        const sanitizeParams = (params: HttpParams) => {
            const res: CapacitorHttpParams = {};
            for (const key of params.keys()) {
                res[key] = decodeURIComponent(params.get(key) || '');
            }
            return res;
        };
        const options: HttpOptions = {
            url,
            method,
            webFetchExtra: {
                credentials: 'include',
                method: method,
                mode: 'no-cors',
            },
            headers: {
                ...sanitizeHeaders(headers),
                Accept: 'application/json',
                'Content-Type': headers.has('Content-Type') ? headers.get('Content-Type') : 'application/json',
                'Cache-Control': 'no-cache',
                'X-Platform': this.platform.is('capacitor') ? 'mobile' : 'web',
                'X-ZONE': Intl.DateTimeFormat().resolvedOptions().timeZone,
                'X-SCHOOL': this.user?.activeSchool ? this.user.activeSchool.id : '',
                'X-DATALANGUAGE': this.user?.activeSchool?.dataLanguage ?? 'en',
                'X-LANG': this._translateService.currentLang,
                'X-TENANT': this.user?.tenant ? this.user.tenantId : '',
            },
            params: sanitizeParams(params),
        };

        if (this.user?.token?.length > 0) {
            options.headers['Authorization'] = `Bearer ${this.user.token}`;
        }

        if (method != 'GET' && body) {
            options.data = body;
        }
        // options.responseType = req.responseType ?? "json";

        //console.log("REQUEST", options);
        return defer(() => Http.request(options)).pipe(
            catchError(e => throwError(() => this.handleRequestError(req, e))),
            map(res => {
                console.log('REQUEST-DATA', options, res);
                this._sharedService.changeApiProgress.next(false);
                if (res.status >= 400) {
                    let errorResponse = new HttpErrorResponse({
                        error: res.data,
                        headers: new HttpHeaders(res.headers),
                        url: res.url,
                        status: res.status,
                    });
                    throw this.handleRequestError(req, errorResponse);
                }
                if (res.data) {
                    let body = deepCopy(res.data);
                    body = this.convertResponse(body);
                    console.log('RESPONSE', res);
                    return new HttpResponse({ body: body });
                    // console.log('CONVERTED RESPONSE', body);
                } else return new HttpResponse({ body: res.data });
            })
        );
    }

    private handleRequestError(request: HttpRequest<any>, error: HttpErrorResponse) {
        const newError = error.error ? JSON.parse(error.error) : error;
        console.log('REQUEST', request);
        console.log('REQUEST-ERROR', error);
        // unauthorized
        if (error.status == 401 || error.status == 500) {
            this._loaderService.dismissLoader();
            if (request.url.indexOf('/auth/login') > -1) {
                this._toastService.showErrorToast(newError.title);
            } else {
                if (this._principal.isAuthenticated()) {
                    console.log('LOGOUT-CapInterceptorError');
                    //this._loginService.logout();
                }
            }
        } else if (error.status == 504) {
            this._toastService.showErrorToast(
                'Campus temporarily unavailable! It is down or not yet started!',
                'warning'
            );
        } else {
            return throwError(() => error);
        }

        //return error;
    }

    // Web angular
    interceptWebRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('ANGULAR HTTP CALL');
        if (!navigator.onLine) {
            return;
        }
        this._sharedService.changeApiProgress.next(true);
        if (request.body && !(request.body instanceof FormData)) {
            // this.convertRequest(Object.assign({}, request.body));
            const data = deepCopy(request.body);
            this.convertRequest(data);
            request = request.clone({
                body: data,
            });
        }
        // console.log('Authenticated:', this.service.isAuthenticated());
        // console.log('Orj Content-Type', orj);
        // console.log('TimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
        const header = this.addHeaders(request);
        console.log('REQUEST-HEADER', header);
        const h$ = of(header);
        return from(h$).pipe(
            switchMap(newReq =>
                next.handle(newReq).pipe(
                    //this.retryAfterDelay(),
                    tap(
                        (val: HttpEvent<any>) => {
                            if (environment.development) {
                                console.log('REQUEST', request);
                            }
                            if (val instanceof HttpResponse) {
                                const body = val.body;
                                this.convertResponse(body);
                                // console.log('CONVERTED RESPONSE', body);
                            }
                            return val;
                        },
                        (error: any) => {
                            console.log('REQUEST', request);
                            console.log('REQUEST-ERROR', error);
                            // unauthorized
                            if (error.status == 401 || error.status == 500) {
                                this._loaderService.dismissLoader();
                                if (request.url.indexOf('/auth/login') > -1) {
                                    this._toastService.showErrorToast(error.error.title);
                                } else {
                                    if (this._principal.isAuthenticated()) {
                                        console.log('LOGOUT-CapInterceptorWebRequest');
                                        //this._loginService.logout();
                                    }
                                }
                            } else if (error.status == 504) {
                                this._toastService.showErrorToast(
                                    'Campus temporarily unavailable! It is down or not yet started!',
                                    'warning'
                                );
                            } else {
                                return throwError(() => error);
                            }
                        }
                    ),
                    finalize(() => {
                        console.log('Http request completed');
                        //this._loaderService.dismissLoader();
                        this._sharedService.changeApiProgress.next(false);
                    })
                )
            )
        );
    }

    // Adds the token to your headers if it exists
    private addHeaders(request: HttpRequest<any>): HttpRequest<any> {
        console.log('OLD-HEADER', request.headers);
        if (this.user) {
            request = request.clone({
                headers: request.headers
                    .set('X-TENANT', this.user.tenant ? this.user.tenantId : '')
                    .set('X-LANG', this._translateService.currentLang ?? 'en')
                    .set('X-DATALANGUAGE', this.user.activeSchool?.dataLanguage ?? 'en')
                    .set('X-SCHOOL', this.user.activeSchool ? this.user.activeSchool.id : '')
                    //.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept')
                    //.set('Access-Control-Allow-Private-Network', 'true')
                    //.set('Access-Control-Allow-Credentials','true')
                    .set('X-VERSION', environment.version)
                    .set('X-Platform', this.platform.is('capacitor') ? 'mobile' : 'web')
                    // .set('x-xsrf-token', this.cookieService.get('X-CSRF-TOKEN'))
                    .set('X-ZONE', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    // .set('X-ZONE', user.timeZone ? user.timeZone : 'UTC')
                    .set('Content-Type', 'application/json')
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache'),
            });
        } else {
            request = request.clone({
                headers: request.headers
                    .set('X-VERSION', environment.version)
                    .set('X-ZONE', Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .set('Content-Type', 'application/json')
                    .set('Cache-Control', 'no-cache')
                    .set('X-Platform', this.platform.is('capacitor') ? 'mobile' : 'web')
                    .set('Pragma', 'no-cache'),
            });
        }
        if (request.body && request.body instanceof FormData) {
            request = request.clone({
                headers: request.headers.delete('content-type'),
            });
        }
        if (this.user?.token?.length > 0) {
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${this.user.token}`),
            });
        }
        return request;
    }

    isIsoDateString(value: any): boolean {
        if (value == null || value == undefined) {
            return false;
        }
        if (value?.length == 10 && typeof value == 'string') {
            return this._isoDateFormat.test(value);
        }
        return false;
    }

    isIsoDateTimeString(value: any): boolean {
        if (value == null || value == undefined) {
            return false;
        }
        if ((value?.length >= 19 || value?.length <= 23) && typeof value == 'string') {
            return this._isoDateTimeFormat.test(value);
        }
        return false;
    }

    convertResponse(body: any) {
        if (body == null || body == undefined) {
            return body;
        }
        if (typeof body != 'object') {
            return body;
        }
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIsoDateString(value)) {
                body[key] = parseISO(value);
            } else if (this.isIsoDateTimeString(value)) {
                body[key] = parseISO(value);
            } else if (typeof value == 'object') {
                body[key] = this.convertResponse(value);
            }
        }
        return body;
    }

    convertRequest(body: any) {
        if (body) {
            //console.log("Before Convert-Request", {...body});
        }
        if (body == null || body == undefined) {
            return body;
        }
        if (typeof body != 'object') {
            return body;
        }
        if (body instanceof Blob) {
            return body;
        }
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (value && isDate(value)) {
                if (!this.isDateTimeDecoratorUsingInstance(body, key)) {
                    body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
                } else {
                    body[key] = new Date(
                        Date.UTC(
                            value.getFullYear(),
                            value.getMonth(),
                            value.getDate(),
                            value.getHours(),
                            value.getMinutes()
                        )
                    );
                }
            } else if (body.hasOwnProperty(key) && typeof body[key] == 'object') {
                body[key] = this.convertRequest(body[key]);
            }
        }
        return body;
    }

    isDateTimeDecoratorUsingInstance(instance: any, propertyKey: string) {
        console.log('FIELDS', instance.dateWithTimeFields);
        return instance.dateWithTimeFields?.length > 0 ? instance.dateWithTimeFields.indexOf(propertyKey) > -1 : false;
    }
}
