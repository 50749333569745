import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';

import { AlertController, IonCol, IonGrid, IonRow, IonSpinner, ModalController } from '@ionic/angular/standalone';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../../directives/swiper.directive';
import { CalendarEvent } from '../../../model/calendar-event.model';
import { TimeFormatType } from '../../../model/school.model';
import { TimeFormatPipe } from '../../../pipes/time-format.pipe';
import { today, utcDate } from '../../../util/date-util';
import { isTeacher } from '../../../util/utils';
import { AttendanceComponent } from '../attendance/attendance.component';

@Component({
    selector: 'slider-event',
    templateUrl: 'slider-event.html',
    styleUrls: ['./slider-event.scss'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [TimeFormatPipe, SwiperDirective, IonGrid, IonRow, IonCol, IonSpinner],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderEvent {
    alertCtrl = inject(AlertController);
    router = inject(Router);
    private cdr = inject(ChangeDetectorRef);
    private modalCtrl = inject(ModalController);

    selectedSlide = 2;
    _isLoading = false;
    _events: CalendarEvent[] = [];
    isToday = false;
    isAnySelected = false;
    isTeacher = isTeacher();
    config: SwiperOptions = {
        slidesPerView: 'auto',
        initialSlide: this.selectedSlide,
        spaceBetween: 8,
        allowSlideNext: true,
        allowSlidePrev: true,
        centeredSlides: true,
        height: 100,
    };
    @ViewChild('swiper') swiper: ElementRef | undefined;
    @Input()
    format: TimeFormatType;
    @Input()
    set isLoading(val: boolean) {
        this._isLoading = val;
        this.cdr.detectChanges();
    }
    @Input('events')
    set events(val: CalendarEvent[]) {
        this._events = val;
        if (this._events?.length > 0) {
            const eventDate = utcDate(this._events[0]?.eventDate);
            this.isToday = isEqual(today(), eventDate);
            if (this.isToday) {
                const now = new Date();
                let index = 0;
                this._events.forEach(event => {
                    const startDate = new Date().setHours(
                        parseInt(event.eventStartTime.substring(0, 2)),
                        parseInt(event.eventStartTime.substring(3, 5))
                    );

                    const endDate = new Date().setHours(
                        parseInt(event.eventEndTime.substring(0, 2)),
                        parseInt(event.eventEndTime.substring(3, 5))
                    );

                    if (isAfter(now, new Date(startDate)) && isBefore(now, new Date(endDate))) {
                        event.referenceId = 'ACTIVE';
                        this.isAnySelected = true;
                        this.selectedSlide = index;
                        this.swiper?.nativeElement?.swiper?.slideTo(index);
                    }
                    index++;
                });
            }
        }
        console.log('EVENTS', this._events);
        this.cdr.markForCheck();
    }
    constructor() {
        console.log('HOME PAGE');
    }

    selectEvent(event: CalendarEvent) {
        this._events.forEach(e => (e.referenceId = ''));
        event.referenceId = 'ACTIVE';
        this.isAnySelected = true;
        if (this.isTeacher) {
            this.openModal(event);
        }
    }
    onSlideChanged(event: any) {
        console.log('Slide Event', event);
    }
    async openModal(selectedEvent) {
        const modal = await this.modalCtrl.create({
            component: AttendanceComponent,
            componentProps: {
                event: selectedEvent,
            },
        });
        modal.present();
    }
}
