import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AlertController,
    IonBadge,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonRouterLink,
    IonRow,
    IonTitle,
    IonToolbar,
} from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe } from '@ngx-translate/core';
import { PrincipalService } from '../../../auth/principal.service';
import { CalendarEvent } from '../../../model/calendar-event.model';
import { Translations } from '../../../providers/translate';
import { UserData } from '../../../providers/user-data';
import { CalendarEventService } from '../../../service/calendar-event.service';
import { IonLoaderService } from '../../../service/loader.service';
import { PreferenceService } from '../../../service/preference.service';
import { DailyCalendarComponent } from '../../slider-daily-calendar/daily-calendar';

@UntilDestroy()
@Component({
    selector: 'student-page-home',
    templateUrl: 'student-home.html',
    styleUrls: ['./student-home.scss'],
    standalone: true,
    imports: [
        FormsModule,
        DailyCalendarComponent,
        FontAwesomeModule,
        TranslatePipe,
        RouterModule,
        IonRouterLink,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonMenuButton,
        IonTitle,
        IonContent,
        IonRefresher,
        IonRefresherContent,
        IonGrid,
        IonRow,
        IonCol,
        IonButton,
        IonBadge,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentHomePage implements OnInit, OnDestroy {
    alertCtrl = inject(AlertController);
    router = inject(Router);
    userData = inject(UserData);
    calendarService = inject(CalendarEventService);
    private cdr = inject(ChangeDetectorRef);
    loaderService = inject(IonLoaderService);
    private principal = inject(PrincipalService);
    translations = inject(Translations);
    private preference = inject(PreferenceService);

    @ViewChild(DailyCalendarComponent) calendar: DailyCalendarComponent;
    todayEvents: CalendarEvent[] = [];
    totalAssignments = 0;
    unreadMessageCount = 0;
    unreadChatMessageCount = 0;
    unreadAnnouncementCount = 0;
    totalCourseCount = 0;
    constructor() {
        console.log('HOME PAGE');
        this.preference.user$.pipe(takeUntilDestroyed()).subscribe(session => {
            if (!session.authenticated) {
                this.router.navigateByUrl('login');
            }
        });
    }

    ngOnInit(): void {
        this.principal.newAnnouncementCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.unreadAnnouncementCount = result;
            this.cdr.detectChanges();
        });
        this.principal.newMessageCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.unreadMessageCount = result;
            this.cdr.detectChanges();
        });
        this.principal.totalAssignmentCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.totalAssignments = result;
            this.cdr.detectChanges();
        });
        this.principal.totalCourseCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.totalCourseCount = result;
            this.cdr.detectChanges();
        });

        this.principal.newChatMessageCount.pipe(untilDestroyed(this)).subscribe(result => {
            this.unreadChatMessageCount = result;
            this.cdr.detectChanges();
        });
    }

    ionViewWillEnter() {
        this.loaderService.dismissLoader();
    }

    handleRefresh(event) {
        setTimeout(() => {
            this.calendar.loadEvents();
            event.target.complete();
        }, 1000);
    }

    ngOnDestroy(): void {
        console.log('destroy');
    }
}
