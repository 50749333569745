import { Injectable, inject } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root',
})
export class IonLoaderService {
    loadingController = inject(LoadingController);

    loader: HTMLIonLoadingElement;

    // Simple loader
    async simpleLoader(message?: string) {
        if (this.loader) {
            return;
        }
        console.log('Loader called!', message);
        this.loader = await this.loadingController.create({
            message: message,
            spinner: 'circles',
            //showBackdrop: false,
        });
        this.loader.present();
    }
    // Dismiss loader
    async dismissLoader() {
        if (this.loader) {
            console.log('Loader closed!');
            this.loader.dismiss().then(() => {
                this.loader = null;
            });
        }
    }
    // Auto hide show loader
    autoLoader(message?: string, duration?: number) {
        this.loadingController
            .create({
                message: message,
                duration: duration,
            })
            .then(response => {
                response.present();
                response.onDidDismiss().then(response => {
                    console.log('Loader dismissed', response);
                });
            });
    }
    // Custom style + hide on tap loader
    customLoader(message?: string, css?: string, duration?: number) {
        this.loadingController
            .create({
                message: message,
                duration: duration,
                cssClass: css,
                backdropDismiss: true,
            })
            .then(res => {
                res.present();
            });
    }
}
